import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { showNotify, closeNotify, showDialog, showConfirmDialog } from 'vant';
const instance = axios.create({
  baseURL: 'https://xy.guodun.org.cn/tenant-api',
  // 可以设置全局的请求超时时间
  timeout: 5000,
  // 可以设置请求头
  headers: {
    // 'Host': 'localhost:48081',
  }
});
export default {
  name: 'Login',
  data() {
    return {
      verificationCode: '',
      userName: '',
      passWord: '',
      uid: '',
      imgUrl: '',
      tenantId: '',
      accessToken: '',
      userId: ''
    };
  },
  created() {},
  mounted() {
    //获取验证码
    this.getVerificationCode();
  },
  methods: {
    //登录验证以及页面跳转
    async goMain() {
      if (this.userName === "") {
        showNotify({
          type: 'warning',
          message: '账号不能为空'
        });
        return;
      }
      if (this.passWord === "") {
        showNotify({
          type: 'warning',
          message: '密码不能为空'
        });
        return;
      }
      if (this.verificationCode === "") {
        showNotify({
          type: 'warning',
          message: '验证码不能为空'
        });
        return;
      }
      await instance.post('/system/auth/win-login', {
        username: this.userName,
        password: this.passWord,
        code: this.verificationCode,
        uuid: this.uid
      }, {
        headers: {
          'Content-Type': 'application/json',
          // 确保发送 JSON 数据
          'tenant-id': this.tenantId
          // 其他你需要的请求头
        }
      }).then(response => {
        if (response.data.code == "0" && response.data != null) {
          if (response.data.data != null) {
            this.accessToken = response.data.data.accessToken;
            this.userId = response.data.data.userId;
            localStorage.setItem("token", this.accessToken);
            localStorage.setItem("tenantId", this.tenantId);
            localStorage.setItem("userId", this.userId);
            //跳转主页面
            this.$router.push({
              path: 'MainEquipment',
              query: {
                id: this.tenantId,
                token: this.accessToken
              }
            });
          }
        } else {
          showDialog({
            title: '警告',
            message: response.data.msg
          }).then(() => {
            this.getVerificationCode();
          });
        }
      }).catch(error => {
        console.error('登录失败!', error);
      });
    },
    //验证用户名
    async getUserName() {
      if (this.userName == "") {
        return;
      }
      const params = {
        // 参数对象
        name: this.userName
      };
      await instance.get('/system/user/get-id-by-name', {
        params
      }).then(response => {
        if (response.data.code == "0") {
          if (response.data.data == null) {
            showNotify({
              type: 'danger',
              message: '用户名不存在'
            });
          } else {
            this.tenantId = response.data.data;
          }
        } else {
          showNotify({
            type: 'danger',
            message: response.data.msg
          });
        }
      }).catch(error => {
        console.error('用户名验证失败!', error);
      });
    },
    //获取验证码
    async getVerificationCode() {
      await instance.get('/system/captcha/captchaImage').then(response => {
        this.imgUrl = "data:image/png;base64," + response.data.img;
        this.uid = response.data.uuid;
      }).catch(error => {
        console.error('验证码获取失败!', error);
      });
    }
  }
};